






































































































































































































































































import DialogMixins from "@/mixins/DialogMixins";
import WidgetMixins from "@/mixins/WidgetMixins";
import { IDeliveryTracking, IUser } from "@/types/types";
import { loadWidget } from "@/utils/helpers";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import timestamp from "@/filters/timestamp";
import truncate from "@/filters/truncate";

const authModule = namespace("auth");
const deliveryTrackingModule = namespace("deliveryTracking");

@Component({
  name: "InTransit",
  components: {
    ToolBar: loadWidget("widgets/ToolBar"),
    EmptyPage: loadWidget("pages/EmptyPage"),
    ProgressBar: loadWidget("widgets/CircularProgressLoader"),
    InternetConnection: loadWidget("pages/InternetConnection"),
    SkeletonPreloader: loadWidget("widgets/SkeletonPreloader")
  },
  filters: {
    timestamp,
    truncate,
    currencyFormat(value: number) {
      return new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GHS",
        minimumFractionDigits: 2
      }).format(value);
    }
  }
})
export default class InTransit extends mixins(WidgetMixins, DialogMixins) {
  @authModule.Getter("getUserDetails") details!: IUser;
  @deliveryTrackingModule.Getter("getEnRouteDeliveries")
  enRouteDeliveries!: IDeliveryTracking[];

  activeId: string = "";

  orderDetails: IDeliveryTracking = {
    id: "",
    trackingNumber: "",
    deliveryProvider: "",
    deliveryRequest: "",
    isDeliveryCompleted: false
  };

  showDetails(payload: IDeliveryTracking): void {
    this.activeId = payload.id;
    this.orderDetails = { ...payload };
  }

  @Watch("details")
  onDetailsChange(payload: IUser) {
    this.$store.dispatch("deliveryTracking/enRouteDeliveries", payload.id);
  }

  created(): void {
    this.$store.dispatch("auth/me");
  }
}
